import React from "react"
import Styles from './homeWhatWeDo.module.css'
import Layout from "./layout"
import {Link} from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import Img from 'gatsby-image'

class whatWeDoSection extends React.Component{
    render(){
        const { data } = this.props;
        console.log(data)
        return(
            <div className={Styles.what_we_do}>
            <div className={"container"}>
              
                <div className="row">
                    <ScrollAnimation animateIn="fadeInRight">
                    <div className="col-lg-5 col-md-12 col-sm-12 col-12 what_we_do_side_content ">
                        <div className={""}>

                        <h1 className={Styles.mainheading}>{data.mainHeading}</h1>
                          <p className={Styles.what_we_do_body}> {data.description.content[0].content[0].value} </p>
                            <Link title="View More" aria-label="View More" className="button" to="/services">View More</Link>

                    </div>
                    </div>
                    </ScrollAnimation>
                </div>
            </div>
         
        </div>
        )
    }
}

export default whatWeDoSection;
