import React from "react"
import Styles from './ourProjects.module.css'
import Layout from "./layout"
import ScrollAnimation from 'react-animate-on-scroll'
import {Link} from "gatsby"

class ourProjects extends React.Component {
    render() {
   
        const {data} = this.props;
       
   
        return (

            <div className={Styles.our_projects_wrap}>

                <Layout>

                    <div className="row">
                        <div className="col-12 "><br/>
                            <div className={Styles.project_list_wrap}>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-sm-12 ">
                                        {/*<h1 className={Styles.project_title}>{data.mainHeading}</h1>*/}
                                        <ScrollAnimation animateIn="fadeIn" >
                                        <p>PROJECTS</p>
                                        <h1 className={Styles.project_title}>{data && data.heading}</h1>
                                        <p>{data.bodyText && data.bodyText.content[0] && data.bodyText.content[0].content[0].value}</p>
                                        <Link title="View projects" aria-label="View projects" className="button white" to="/projects">View
                                            Projects</Link>
                                        </ScrollAnimation> 
                                    </div>
                                    <div className="col-12 col-md-6 col-sm-12 ">
                                        <ScrollAnimation animateIn="fadeInDown" delay={300} >
                                            <div className={Styles.project_ipad}>
                                                <div className={Styles.project_list}>
                                                    <ul>
                                                        <li>
                                                            Educational Developments
                                                        </li>
                                                        <li>
                                                            Commercial & Industrial Developments
                                                        </li>
                                                        <li>
                                                            Medical & Health Developments
                                                        </li>
                                                        <li>
                                                            Housing & Mix Use Developments
                                                        </li>
                                                        <li>
                                                            Hotel & Leisure Developments
                                                        </li>
                                                        <li>
                                                            Office fit-outs
                                                        </li>
                                                        <li>
                                                            Social & Cultural Developments
                                                        </li>
                                                        <li>
                                                            Transaction Advisory
                                                        </li>
                                                        <li>
                                                            Forensic Audit Projects
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>


                </Layout>

            </div>
        )
    }
}

export default ourProjects;
