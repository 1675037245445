import React from "react"
import styles from './experience.module.css'
import Layout from "./layout"
import {Link} from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import Img from 'gatsby-image'

class ourExperience extends React.Component{
    render(){
        const { data } = this.props;
        return(
            <div className={styles.experience}>
            <div className={"container"}>
          <div className={styles.experience_content}>
                <div className="row">
                  
                    
    
                    <div className="col-md-5 col-sm-12 col-12">

                        <div className={styles.experience_image}>
                            <img className={styles.experienceimg} src="https://images.ctfassets.net/x5zolfqdr3vy/3MBhkuVNehwlJA5EOHkUty/83f3688ff343bc13bc8146bb05ce5245/remarkable-experience.jpg" alt="experience image" />
                        <ScrollAnimation animateIn="fadeIn">
                        {/* <Img alt={data.heading} sizes={data.heading.sizes}/> */}
                        </ScrollAnimation>
                        </div>
                  
                    </div>
                    <div className="col-md-7 col-sm-12 col-12 ">
                    <ScrollAnimation animateIn="fadeIn"><h1>{data.heading}</h1></ScrollAnimation>

                        <div className={styles.experience_content}>
                                <ScrollAnimation animateIn="bounceIn" delay={300}> <h2>{data.years}<sup>+</sup></h2></ScrollAnimation> 
                                <ScrollAnimation animateIn="fadeIn" delay={500}><p>Years <br/> <strong>of experience</strong></p></ScrollAnimation>
                         </div>
                    </div>
                 
                </div>
            </div>
            </div>
        </div>
    
        )
    }
}
   


export default ourExperience;
