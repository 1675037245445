import React from 'react';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Navigation from "../components/navigationModule";
import Footer from "../components/footer";
import SEO from "../components/seo";
import get from 'lodash/get';
import Banner from "../components/banner";
import WhatWeDo from "../components/whatWeDo";
import Experience from "../components/ourExperience";
import OurProjects from "../components/ourProjects";



class homePage extends React.Component {

    constructor(data) {
        super(data);
        this.state = {
            currentSection: 1
        }
    }

    render() {

        const banner = get(this, 'props.data.allContentfulHomeBannerSection.nodes')[0]
        const WhatWeDoData = get(this, 'props.data.allContentfulHomeWhatWeDo.nodes')[0]
        const yearsOfExperience = get(this, 'props.data.allContentfulHomeExperienceSection.nodes')[0]
        const homeOurProjectsSection = get(this, 'props.data.allContentfulHomeOurServicesSection.nodes')[0]
        return (
            <>
                <div id="home_wrapper">
                    <SEO title="Home"/>
                    <Navigation/>
                    <ReactFullpage
                            //fullpage options
                            scrollingSpeed = {1000} /* Options here */

                            render={({  }) => {
                            return (
                                <ReactFullpage.Wrapper>
                                <div className="section">
                                    <section className="banner"><Banner data={banner}/></section> 
                                
                                </div>
                              
                                <div className="section">
                                     <section className="what_we_do"><WhatWeDo data={WhatWeDoData}/></section>
                                </div>
                                <div className="section">
                                     <section className="our_projects"><OurProjects data={homeOurProjectsSection}/></section>
                                </div>
                                <div className="section">
                                    <section className="experience"><Experience data={yearsOfExperience}/></section>
                                     <Footer/>
                                </div>
                            
                                </ReactFullpage.Wrapper>
                            );
                            }}
                        />
                   
                 
                   
                  

                </div>

            </>

        )
    }
}

export default homePage;

export const query = graphql`
    {
        allContentfulHomeBannerSection {

            nodes {
                bannerImage {
                    sizes {
                        src
                    }
                }
                bannerTitle
                buttonText
                description{
                    content {
                        content{
                            value
                        }
                    }
                }

            }
        }
        allContentfulHomeOurServicesSection {
            nodes {
              mainHeading
              projectOneHeading
              projectOneShortDescription {
                content {
                  content {
                    value
                  }
                }
              }
              projectTwoHeading
              projectTwoShortDescription {
                content {
                  content {
                    value
                  }
                }
              }
              projectThreeHeading
              projectThreeShortDescription {
                content {
                  content {
                    value
                  }
                }
              }
                projectOneLink
                projectTwoLink
                projectThreeLink 
            }
          }
        allContentfulHomeWhatWeDo {
            nodes {
                mainHeading
                description {
                    content {
                        content {
                            value
                        }
                    }
                }
                subheading
                secondSubHeading
                secondSubHeadingDescription {
                    content {
                        content {
                            value
                        }
                    }
                }
                subHeadingDescription {
                    content {
                        content {
                            value
                        }
                    }
                }
                image {
                    sizes(resizingBehavior: SCALE) {
                        ...GatsbyContentfulSizes_withWebp
                    }
                }
            }
        }

        allContentfulHomeExperienceSection {
            nodes {
                heading
                years
                experienceSectionImage{
                    sizes(resizingBehavior: SCALE) {
                        ...GatsbyContentfulSizes_withWebp
                    }
                }
            }
        }

    }
`;

