import React from "react"
import styles from './banner.module.css'
import Layout from "./layout"
import {Link} from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll'
import BackgroundImage from "react-background-image";


class banner extends React.Component{
    render(){
        const { data } = this.props;
       
        return(<>
            <div className={styles.top_banner}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={styles.firstLayer}>
                            <div className={styles.box_with_text}>

                            </div>
                        </div>
                        <div className={styles.bannerImgOne}>
                            <ScrollAnimation animateIn="lightSpeedIn"  >
                                <img src={"https://images.ctfassets.net/x5zolfqdr3vy/3zYlqkjMK87aW24DUhKh3D/6cf0bf7db52a3f2501ba2f6bb977d587/left_img.png"} width="65%"/>
                            </ScrollAnimation>
                        </div>
                        <div className={styles.bannerImgTwo}>
                            <ScrollAnimation animateIn="fadeInDown"  >
                                <img src={"https://images.ctfassets.net/x5zolfqdr3vy/4Zo1HP5g78gPq29ez31MYW/79164df9fffb32d9267e43518f399fd8/pens.png"} width="20%"/>
                            </ScrollAnimation>
                        </div>
                        <div className={styles.bannerImgThree}>
                            <ScrollAnimation animateIn="fadeIn"  >
                                {/*<img src={"https://images.ctfassets.net/x5zolfqdr3vy/3zYlqkjMK87aW24DUhKh3D/86dad6a2fdf96c399d3627548a9aaa7b/left_img.png"} width="65%"/>*/}
                            </ScrollAnimation>
                        </div>


                            <div className={styles.bannerText}>
                                <ScrollAnimation animateIn="fadeIn">
                                TANDEM & <span>STARK</span>
                                </ScrollAnimation>
                                </div>

                            <div className={styles.bannerImgFour}>
                                <ScrollAnimation animateIn="rotateInUpLeft"  >
                                <img src={"https://images.ctfassets.net/x5zolfqdr3vy/17kXJNLDH2jGHfVmjPcYTd/95898f07c4775563501a637b14e4a1c9/construction_ipad.png"}/>
                                </ScrollAnimation>
                            </div>


                        <div className={styles.bannerImgFive}></div>
                        <div className={styles.bannerImgSix}></div>
                    </div>
                </div>
            </div>
            <div  className={styles.bannerWrapper}>
            <div className={"container home_sections"}>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                    <ScrollAnimation animateIn="fadeIn" >
                         <h1>
                              {data.bannerTitle}
                         </h1>
                   </ScrollAnimation>
                   <ScrollAnimation animateIn="fadeIn" >
                         <p>
                         {data.description.content[0].content[0].value}
                         </p>
                         </ScrollAnimation>
                         <ScrollAnimation animateIn="fadeInUp" >
                         <Link title={data.buttonText} aria-label={data.buttonText} className="button" to="/about">{data.buttonText}</Link>
                         </ScrollAnimation>
                    </div>

                </div>
            </div>


        </div>
            </>
        )
    }
}

export default banner;

